import { useState, useEffect } from "react";
import SectionTitle from "../components/layout/section-title";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useHistory } from "react-router-dom";
import { path as signUpPath } from "./signup-page";
import { path as landingPath } from "./landing-page";
import { login } from "../service/user-service";
import LoadingScreen from "../components/layout/loading-screen";
import { useMediaQuery } from "react-responsive";
import Config from "../config.json";
import { formatPageTitle } from "../service/formatting-service";
import { Helmet } from "react-helmet";

export const path = "/pages/login";
export default function LoginPage({ setCloseableOnMobile }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => setCloseableOnMobile(true), [setCloseableOnMobile]);

  if (loading) {
    return <LoadingScreen className="h-100" />;
  }

  const onLoginFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const response = await login(email, password);
    if (response.message) {
      setErrorMessage(response.message);
      setLoading(false);
    } else {
      setErrorMessage("");
      history.push(landingPath);
    }
  };

  return (
    <>
      <Helmet>
        <title>{formatPageTitle("Login")}</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="Login" />
        <meta property="og:title" content={formatPageTitle("Login")} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Login" />
      </Helmet>
      <SectionTitle
        title={Config.translations[Config.language]['accedi']}
        subtitle={Config.translations[Config.language]['accedi_desc']}
      />

      <form className="mb-5" onSubmit={onLoginFormSubmit}>
        <div className="row mb-4 mt-md-5 justify-content-center">
          <TextField
            label="Email"
            variant="standard"
            className="col-10 col-md-6"
            type="email"
            placeholder="mario.rossi@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            color="textInputMobileAccent"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Password"
            variant="standard"
            type="password"
            className="col-10 col-md-6"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            color="textInputMobileAccent"
          />
        </div>
        
        <div className="row justify-content-center">
          <sub className="mb-5 mt-2 text-gray-3 col-10 col-md-6 text-right p-0">
            {/*
            Hai dimenticato la{" "}
            <u>
              <Link
                className="colored-link text-gray-3"
                to="/password-recovery"
              >
                password?
              </Link>
            </u>
            */}
          </sub>
        </div>

        {errorMessage && (
          <div className="row justify-content-center my-4">
            <div
              className="col-12 col-md-10 text-center text-danger"
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            ></div>
          </div>
        )}

        <div className="row justify-content-center">
          <div className="col-10 col-md-3 mb-3 px-0 pr-md-2">
            <Button
              variant="contained"
              className="w-100"
              type="submit"
              color="buttonLoginDesktop"
            >
              {Config.translations[Config.language]['accedi']}
            </Button>
          </div>
          <Link to={signUpPath} className="col-10 col-md-3 px-0 pl-md-2">
            <Button
              variant={
                Config.activeTheme === "additional" && !isMobile
                  ? "contained"
                  : "outlined"
              }
              className="w-100"
              color="button"
            >
              Registrati
            </Button>
          </Link>
        </div>
        <div className="row justify-content-center mb-4 p-0">
          <sub className="text-gray-3 text-right col-10 col-md-6 p-0 mt-2">
            Informativa{" "}
            <u>
              <Link
                className="colored-link text-gray-3"
                to="/pages/content/privacy"
              >
                privacy
              </Link>
            </u>
          </sub>
        </div>
      </form>
    </>
  );
}
