import {
  useState,
  useEffect,
} from "react";
import SectionTitle from "../../components/layout/section-title";
import { useHistory } from "react-router-dom";
import { path as paymentOnePath } from "./payment1-page";
import { path as cartPath } from "../cart-page";
import LoadingScreen from "../../components/layout/loading-screen";
//import { getStripeIntentId } from "../../service/orders-service";
import { formatPageTitle } from "../../service/formatting-service";
import { Helmet } from "react-helmet";
import { getCart } from "../../service/cart-service";
import PaymentBox from "../../components/payment-box";

export const path = "/payment/paymentnoshipping";
export default function PaymentNoShippingPage({ setBarPercentageLength, location }) {
  const [loading, setLoading] = useState(true);
  //const [paymentIntentId, setPaymentIntentId] = useState("");
  const history = useHistory();
  const [cart, setCart] = useState({});

  useEffect(() => {
    let isSubscribed = true;
    if (!location.state) {
      // Returns to the first payment page if no state is passed (e.g. a page refresh)
      history.replace(paymentOnePath);
    } else {
      setBarPercentageLength(66);
      //const { id, total } = location.state;
      setLoading(false);
      /*
      getStripeIntentId(id, total).then(({ payment_intent_id }) => {
        if (isSubscribed) {
          setPaymentIntentId(payment_intent_id);
          setLoading(false);
        }
      });
      */
    }
    Promise.all([
      getCart()
    ]).then(
      ([cartResult]) => {
        if (isSubscribed) {
          setCart(cartResult);
          if (!cartResult || cartResult.length < 1) {
            history.replace(cartPath);
          }
        }
      }
    );
    return () => (isSubscribed = false);
  }, [setBarPercentageLength, location.state, history]);

  if (loading) {
    return <LoadingScreen />;
  }


  return (
    <>
      
    <div className="pb-md-3 pt-3 row flex-fill">
      <div className="col layout-max-width">
      <SectionTitle title="Pagamento e spedizione" subtitle="Spedizione non supportata"/>      
        
        <div className="row justify-content-center">
        <div className="col-12 col-md-6" style={{textAlign: 'center'}}>
            <Helmet>
              <title>{formatPageTitle("Pagamento e spedizione")}</title>
            </Helmet>
            Purtroppo la spedizione a questo indirizzo non è attualmente supportata. <br/><br/>Scrivi <a href="mailto:info@fuocolegnopietra.it">QUI</a> per concordare le modalità di spedizione e avere un preventivo, <br/>oppure clicca <a href="/payment/payment1">QUI</a> per impostare un indirizzo diverso
          </div>
          <div className="col-12 col-md-4 px-0">
            <PaymentBox
              cart={cart}
              title="Riepilogo Pagamento"
              className="p-3"
              false
            />
          </div>
        </div>
        </div>
      </div>
    </>
  );
}