import { Button } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../components/layout/section-title";
import { path as landingPath } from "./landing-page";
import { Helmet } from "react-helmet";
import { formatPageTitle } from "../service/formatting-service";

export const path = "/pages/404";
export default function NotFoundPage({ setCloseableOnMobile }) {
  useEffect(() => setCloseableOnMobile(false), [setCloseableOnMobile]);

  return (
    <>
      <Helmet>
        <title>{formatPageTitle("Not found")}</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="Not found" />
        <meta property="og:title" content={formatPageTitle("Not found")} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Not found" />
        <meta name="prerenderer:status" content="404" />
      </Helmet>
      <SectionTitle title="Oh no!" />
      <div className="row justify-content-center">
        <h2 className="col col-md-10 fw-600 font-18-24 theme-color-primary">
          Errore 404
        </h2>
      </div>
      <div className="row justify-content-center">
        <p className="col col-md-10 fw-600 text-gray-2 font-16">
          Non è stato possibile trovare la pagina che stavi cercando.
        </p>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col col-md-10">
          <Link to={landingPath}>
            <Button variant="outlined" color="button">
              Continua a navigare
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
