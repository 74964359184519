import { useState, useEffect } from "react";
import SectionTitle from "../../components/layout/section-title";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { path as paymentTwoPath } from "./payment2-page";
import { path as paymentNoShippingPath } from "./paymentnoshipping-page";
import { path as cartPath } from "../cart-page";
import LoadingScreen from "../../components/layout/loading-screen";
import {
  cleanAddress,
  formatPageTitle,
} from "../../service/formatting-service";
import {
  getCustomerInfo,
  saveCustomerInfo,
  getUserId,
  getUserProfile,
} from "../../service/user-service";
import AddressForm from "../../components/address-form";
import { createOrder } from "../../service/orders-service";
import { getCart } from "../../service/cart-service";
import { Helmet } from "react-helmet";
import PaymentBox from "../../components/payment-box";


export const path = "/payment/payment1";
export default function Payment1Page({ setBarPercentageLength }) {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [billingAddress, setBillingAddress] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const [shippingLines, setShippingLines] = useState({});
  const [showBillingAddress, setShowBillingAddress] = useState(false);
  const [cart, setCart] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  useEffect(() => {
    let isSubscribed = true;

    Promise.all([
      getCustomerInfo(getUserId()),
      getUserProfile(),
      getCart(),
    ]).then(
      ([{ billing, shipping }, { first_name, last_name }, cartResult]) => {
        if (isSubscribed) {
          cleanAddress(billing);
          cleanAddress(shipping);
          console.log("--------SHIPPING--------");
          console.log(shipping);
          console.log("--------SHIPPING--------");
          setBillingAddress(billing);
          setShippingAddress(shipping);

          // If the billing address is set, show the form
          if (billing.address_1) {
            setShowBillingAddress(true);
          }

          setName(first_name);
          setSurname(last_name);
          setCart(cartResult);
          setLoading(false);

          if (!cartResult || cartResult.length < 1) {
            history.replace(cartPath);
          }
        }
      }
    );
    return () => (isSubscribed = false);
  }, [history]);

  const totals = cart?.totals;
  const shippingTotal = totals?.shipping_total;
  //const numRates = cart?.shipping.packages.default? Object.keys(cart.shipping.packages.default.rates).length : 0;

  useEffect(() => setBarPercentageLength(33), [setBarPercentageLength]);

  if (loading) {
    return <LoadingScreen />;
  }

  const onOrderSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const orderItems = cart.items.map(({ id, quantity }) => {
      return { product_id: id, quantity: quantity.value };
    });
    shippingAddress.first_name = name;
    shippingAddress.last_name = surname;
    billingAddress.first_name = billingAddress.first_name? billingAddress.first_name : shippingAddress.first_name;
    billingAddress.last_name = billingAddress.last_name? billingAddress.last_name : shippingAddress.last_name;
    billingAddress.address_1 = billingAddress.address_1? billingAddress.address_1 : shippingAddress.address_1;
    billingAddress.address_2 = billingAddress.address_2? billingAddress.address_2 : shippingAddress.address_2;
    billingAddress.city = billingAddress.city? billingAddress.city : shippingAddress.city;
    billingAddress.country = billingAddress.country? billingAddress.country : shippingAddress.country;
    billingAddress.postcode = billingAddress.postcode? billingAddress.postcode : shippingAddress.postcode;
    billingAddress.state = billingAddress.state? billingAddress.state : shippingAddress.state;
    billingAddress.company = billingAddress.company? billingAddress.company : shippingAddress.company;

    //Aggiorno Default Utente
    const response = await saveCustomerInfo(getUserId(), {
      billing: billingAddress,
      shipping: shippingAddress,
    });
    if (response.message) {
      setErrorMessage(response.message);
      setLoading(false);
    }else{

      //Ricalcolo Spese spedizione su nuovo addr
      const newcart = await getCart();
      const newnumRates = newcart.shipping.packages.default? Object.keys(newcart.shipping.packages.default.rates).length : 0;
      var shipping = newnumRates > 0? newcart?.totals.shipping_total : null;
      console.log("\n=============\n");
      console.log("SHIPPING : "+shipping);
      console.log("\n=============\n");
      if(!shipping){
        history.push({ pathname: paymentNoShippingPath , state: { } });
      }else{


        console.log("\n\n\n*******************\n");
        console.log(newcart.shipping.packages.default.chosen_method);
        console.log(newcart.shipping.packages.default.rates[newcart.shipping.packages.default.chosen_method]);
        console.log("\n*******************\n\n\n");

        //Recupero shipping line e creo ordine
        var choosen_method_id = newcart.shipping.packages.default? newcart.shipping.packages.default.chosen_method : null;
        if(choosen_method_id){
          shippingLines.method_id = newcart.shipping.packages.default? newcart.shipping.packages.default.rates[choosen_method_id].method_id : null;
          shippingLines.method_title = newcart.shipping.packages.default? newcart.shipping.packages.default.rates[choosen_method_id].label : '';
          shippingLines.total = newcart.totals.shipping_total;
        }
        
        const { id, total, message } = await createOrder(
          billingAddress,
          shippingAddress,
          orderItems,
          [shippingLines]
        );
        if (message) {
          setErrorMessage(message);
          setLoading(false);
        } else {
          history.push({ pathname: paymentTwoPath, state: { id, total } });
          setLoading(false);
        }
      }
    }
  }

  return (
    <>
      
      <div className="pb-md-3 pt-3 row flex-fill">
        <div className="col layout-max-width">
          <SectionTitle title="Pagamento e spedizione" subtitle={(shippingTotal==='0,00')? "Inserisci le info di spedizione per calcolare eventuali spese di consegna" : "Aggiorna le info di spedizione per ricalcolare eventuali spese di spedizione"} />
        
          <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <form className="col layout-max-width" onSubmit={onOrderSubmit}>
              <Helmet>
                <title>{formatPageTitle("Pagamento e spedizione")}</title>
              </Helmet>
              <div className="row justify-content-center">
                <TextField
                  label="Nome"
                  variant="standard"
                  className="col-md-10 col-10"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  color="textInput" />
              </div>
              <div className="row justify-content-center mt-3">
                <TextField
                  label="Cognome"
                  variant="standard"
                  className="col-md-10 col-10"
                  type="text"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  required
                  color="textInput" />
              </div>
              <AddressForm
                address={shippingAddress}
                setAddress={setShippingAddress} />

              <div className="row justify-content-center my-3">
                <p className="col-md-10 col-10 text-left p-0 theme-page-text font-12-14">
                  Indirizzo di{" "}
                  <u
                    className="cursor-pointer"
                    onClick={() => setShowBillingAddress(true)}
                  >
                    fatturazione
                  </u>{" "}
                  diverso da quello di spedizione?
                </p>
              </div>
              {showBillingAddress && (
                <AddressForm
                  address={billingAddress}
                  setAddress={setBillingAddress} />
              )}

              {errorMessage && (
                <div className="row justify-content-center my-4">
                  <div
                    className="col-12 col-md-10 text-center text-danger"
                    dangerouslySetInnerHTML={{ __html: errorMessage }}
                  ></div>
                </div>
              )}

              <div className="row justify-content-center my-4">
                <div className="col-10 col-md-10 p-0">
                  <Button
                    className="w-100"
                    type="submit"
                    variant="contained"
                    color="button"
                  >
                    Procedi
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 col-md-4 px-0">
            <PaymentBox
              cart={cart}
              title="Riepilogo Pagamento"
              className="p-3"
              false
              shipping={(cart?.shipping.packages.default? Object.keys(cart.shipping.packages.default.rates).length : 0)>0? cart?.totals.shipping_total : null}
            />
          </div>
          </div>
        </div>
      </div></>
  );
}
