import Config from "../../config.json";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { path as ordersPath } from "../orders-page";
import { path as cartPath } from "../cart-page";
import Payment1Page, { path as payment1Path } from "./payment1-page";
import Payment2Page, { path as payment2Path } from "./payment2-page";
import Payment3Page, { path as payment3Path } from "./payment3-page";
import PaymentNoShippingPage, { path as PaymentNoShippingPath } from "./paymentnoshipping-page";

import { Switch } from "react-router-dom";
import ProtectedRoute from "../../components/protected-route";
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import LoadingScreen from "../../components/layout/loading-screen";
import { loadStripe } from "@stripe/stripe-js/pure";
import { getStripePublicKey } from "../../service/orders-service";
import { Route, Redirect } from "react-router-dom";
import { path as notFoundPath } from "../notfound-page";
import { formatPageTitle } from "../../service/formatting-service";
import { Helmet } from "react-helmet";

export const path = "/payment";
export default function PaymentLayout() {
  const [barPercentageLength, setBarPercentageLength] = useState(33);
  const [loading, setLoading] = useState(true);
  const [stripe, setStripe] = useState({});
  //const [cart, setCart] = useState([]);

  const history = useHistory();

  const headerLogoUrl = Config.themes[Config.activeTheme].headerLogo;
  const headerLogo = headerLogoUrl.startsWith("/")
    ? process.env.PUBLIC_URL + headerLogoUrl
    : headerLogoUrl;

  useEffect(() => {
    let isSubscribed = true;
    if(Config.buy){
      getStripePublicKey().then(({ publishable_key }) => {
        if (isSubscribed) {
          loadStripe(publishable_key).then((stripe) => {
            if (isSubscribed) {
              setStripe(stripe);
              setLoading(false);
            }
          });
        }
        return () => (isSubscribed = false);
      });
    }else{
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <div className="container-fluid min-vh-100">
        <LoadingScreen className="min-vh-100" />
      </div>
    );
  }

  const closePayment = () => {
    if(barPercentageLength>66)
      history.push(ordersPath);
    else
      history.push(cartPath);
  };

  const progressBarStyle = {
    width: barPercentageLength + "%",
  };

  return (
    <div className="container-fluid min-vh-100 d-flex flex-column theme-page-background">
      <Helmet>
        <title>{formatPageTitle("Pagamento e spedizione")}</title>
      </Helmet>
      <div className="row payment-header justify-content-center align-items-center theme-header">
        <div className="col layout-max-width">
          <div className="row align-items-center justify-content-center">
            <div className="col col-md-5 text-left">
              <img id="payment-top-logo" src={headerLogo} alt="logo" />
            </div>
            <div className="col col-md-5 text-right theme-header-text">
              <CloseIcon className="cursor-pointer" onClick={closePayment} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <hr className="col" />
      </div>
      <div className="row">
        <div className="col px-0">
          <hr className="payment-progress" style={progressBarStyle} />
        </div>
      </div>
          <Elements stripe={stripe}>
            <Switch>
              <ProtectedRoute
                path={payment1Path}
                component={Payment1Page}
                setBarPercentageLength={setBarPercentageLength}
                authenticated={true}
              />
              <ProtectedRoute
                path={payment2Path}
                component={Payment2Page}
                setBarPercentageLength={setBarPercentageLength}
                authenticated={true}
              />
              <ProtectedRoute
                path={payment3Path}
                component={Payment3Page}
                setBarPercentageLength={setBarPercentageLength}
                authenticated={true}
              />
              <ProtectedRoute
                path={PaymentNoShippingPath}
                component={PaymentNoShippingPage}
                setBarPercentageLength={setBarPercentageLength}
                authenticated={true}
              />
              <Route>
                <Redirect to={notFoundPath} />
              </Route>
            </Switch>
          </Elements>

      <div className="row">
        <hr className="col" />
      </div>

      <div className="row justify-content-center payment-footer align-items-center theme-footer">
        <div className="col layout-max-width">
          <div className="row my-2">
            <div className="col text-center">
              <img
                id="payment-bottom-logo"
                src={
                  process.env.PUBLIC_URL +
                  Config.themes[Config.activeTheme].logo
                }
                alt="logo"
              />
            </div>
          </div>
          <div className="row justify-content-center">
          <p className="fw-300 font-12-14 text-center mb-0 col layout-max-width theme-footer-text">
            {Config.company.address} • {Config.company.postalcode} • {Config.company.city} • {Config.company.copywrite} • {Config.company.vat}
          </p>
          </div>
        </div>
      </div>
    </div>
  );
}
