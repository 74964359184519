import DynamicLink from "./dynamic-link";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import Config from "../../config.json";
import { useSwipeable } from "react-swipeable";

export default function CarouselMenu({ menu, slides, styles }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (slides) {
      const updateSlide = () => {
        if (currentSlide === slides.length - 1) {
          setCurrentSlide(0);
        } else {
          setCurrentSlide(currentSlide + 1);
        }
      };
      const timeout = setTimeout(updateSlide, Config.slidesSpeed);
      return () => clearTimeout(timeout);
    }
  }, [slides, currentSlide]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentSlide(
        currentSlide === slides.length - 1 ? 0 : currentSlide + 1
      ),
    onSwipedRight: () =>
      setCurrentSlide(
        currentSlide === 0 ? slides.length - 1 : currentSlide - 1
      ),
  });

  const menuHtml = menu?.map((m, i) => (
    <DynamicLink
      className={
        "menu-item p-3 link-unstyled fw-500 font-16 " +
        (slides ? "theme-slide-menu" : "text-gray-3")
      }
      {...m}
      key={i}
    >
      {m.title}
    </DynamicLink>
  ));

  const slideSelectors = slides?.map((s, i) => (
    <span
      className={
        "slide-dot cursor-pointer" +
        (i === currentSlide ? " slide-dot-selected" : "")
      }
      onClick={() => setCurrentSlide(i)}
      key={i}
    ></span>
  ));

  const slidesHtml = slides?.map((s, i) => (
    <img
      className="col p-0 full-grid highlight-carousel-slide left-right-animation"
      src={s.primopiano_img}
      key={i}
      alt={s.primopiano_titolo}
      style={{
        left: (i - currentSlide) * window.innerWidth,
      }}
    />
  ));

  return (
    <>
      {!slides ? (
        <div className="row justify-content-center align-items-center theme-page-background d-md-flex d-none theme-header-text">
          <div className="col layout-max-width">
            <div className="row justify-content-center">{menuHtml}</div>
          </div>
        </div>
      ) : (
        <>
          {menuHtml && Config.activeTheme === "vendor" && (
            <div className="row justify-content-center align-items-center d-md-flex d-none">
              {menuHtml}
            </div>
          )}
          <div className="row">
            <div className="col p-0 d-grid overflow-hidden" {...swipeHandlers}>
              <div className="row full-grid m-0">
                <div className="highlight-carousel-slide col d-grid p-0">
                  {slidesHtml}
                </div>
              </div>
              <div className="highlight-carousel-slide row full-grid">
                <div className="col black-overlay-gradient">
                  <div className="row h-100">
                    <div className="col layout-max-width h-100">
                      <div className="d-flex flex-column h-100 p-0">
                        {menuHtml && Config.activeTheme === "main" && (
                          <div className="row justify-content-center align-items-center d-md-flex d-none">
                            {menuHtml}
                          </div>
                        )}
                        <div className="h-100 d-flex flex-column justify-content-end col">
                          <div
                            className={
                              "row justify-content-center " +
                              (styles?.rectangleRow ?? "")
                            }
                          >
                            <div className="col-md-10 col-12">
                              <div className="highlight-rectangle" />
                            </div>
                          </div>
                          <div
                            className={
                              "row justify-content-center " +
                              (styles?.subtextRow ?? "")
                            }
                          >
                            <div className="col-md-10 col-12">
                              <p className="font-18 mb-0 text-white fw-300">
                                {slides[currentSlide]?.primopiano_occhiello}
                              </p>
                            </div>
                          </div>
                          <div
                            className={
                              "row justify-content-center theme-font-family " +
                              (styles?.titleRow ?? "")
                            }
                          >
                            <div className="col-md-10 col-12">
                              <p className=" fw-600 mb-0 text-white font-26-45">
                                {slides[currentSlide]?.primopiano_titolo}
                              </p>
                            </div>
                          </div>
                          { (slides[currentSlide]?.primopiano_link)? (
                          <div
                            className={
                              "row justify-content-center pb-md-0 pb-5 " +
                              (styles?.buttonRow ?? "")
                            }
                          >
                            <div className="col-md-10 col-12">
                              <DynamicLink
                                url={slides[currentSlide]?.primopiano_link}
                              >
                                <Button
                                  className="col-md-3 col-6 mt-2 mt-md-0 fw-300"
                                  variant="contained"
                                  color="slides"
                                >
                                  {slides[currentSlide]?.primopiano_link_label}
                                </Button>
                              </DynamicLink>
                            </div>
                          </div>
                          ) : (<div></div>)}
                          <div
                            className={
                              "row justify-content-center pb-md-5 " +
                              (styles?.slideControllerRow ?? "")
                            }
                          >
                            <div className="col-md-10 col-12 text-right">
                              {slideSelectors}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
