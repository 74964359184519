import { useState, useEffect } from "react";
import SectionTitle from "../components/layout/section-title";
import CartRow from "../components/cart-row";
import {
  getCart,
  removeProductFromCart,
  updateItemQuantity,
} from "../service/cart-service";
import LoadingScreen from "../components/layout/loading-screen";
import PaymentBox from "../components/payment-box";
import { formatPageTitle } from "../service/formatting-service";
import { Helmet } from "react-helmet";
import Config from "../config.json";

export const path = "/pages/cart";
export default function CartPage({ setCloseableOnMobile, setCartItems }) {
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);

  useEffect(() => setCloseableOnMobile(false), [setCloseableOnMobile]);

  useEffect(() => {
    let isSubscribed = true;
    getCart().then((cart) => {
      if (isSubscribed) {
        setCart(cart);
        setLoading(false);
      }
    });
    return () => (isSubscribed = false);
  }, []);

  const totals = cart?.totals;
  const shippingTotal = totals?.shipping_total;

  if (loading) {
    return <LoadingScreen className="h-100" />;
  }

  const onRowDelete = async (id) => {
    setLoading(true);
    await removeProductFromCart(id);
    const cart = await getCart();
    setCartItems(cart.item_count);
    setCart(cart);
    setLoading(false);
  };


  
  const onQuantityUpdate = async (id, item) => {
    var newQuantity = item.value;
    if(newQuantity){
      setLoading(true);
      const cart = await updateItemQuantity(id, newQuantity);
      setCartItems(cart.item_count);
      setCart(cart);
      setLoading(false);
    }
  };
  


  const numRates = cart?.shipping?.packages?.default? Object.keys(cart.shipping.packages.default.rates).length : 0;

  const cartRows = cart?.items?.map((o, i) => (
    <CartRow
      {...o}
      key={i}
      onRowDelete={onRowDelete}
      onQuantityUpdate={onQuantityUpdate}
      shipping={numRates>0? cart?.totals.shipping_total : null}
      totals={cart?.totals}
      />
  ));
  const articlesNumber = cart?.item_count ?? 0;

  //const articlesTotal = cart.reduce((prev, curr) => prev.price + curr.price, 0);

  const title = articlesNumber + " " + Config.translations[Config.language]['carrello_tit'];

  return (
    <>
      <Helmet>
        <title>{formatPageTitle("Carrello")}</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="Carrello" />
        <meta property="og:title" content={formatPageTitle("Carrello")} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Carrello" />
      </Helmet>
      <SectionTitle
        title={title}
        subtitle={(shippingTotal==='0,00')? Config.translations[Config.language]['carrello_desc_1'] : Config.translations[Config.language]['carrello_desc_2']}
      />
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">{cartRows}</div>
        <div className="col-12 col-md-4 px-0">
          <PaymentBox
            cart={cart}
            title={Config.translations[Config.language]['metodo_pagamento']}
            className="p-3"
            showPayment
            shipping={numRates>0? cart?.totals.shipping_total : null}
          />
        </div>
      </div>
    </>
  );
}
