import ProductCarousel from "../../product-carousel";
import Config from "../../../config.json";

export default function HighlightsSection({
  lastOffers,
  expiringOffers,
  exclusiveOffers,
  categ1Offers,
  categ2Offers,
  categ3Offers
}) {
  return (
    <div className="row my-5">
      <div className="col layout-max-width">
      { (categ1Offers)? (
        (Object.keys(categ1Offers).length > 0)? (
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <ProductCarousel title={Config.homeSlider1Title} items={categ1Offers} />
            </div>
          </div>
          ) : (<div></div>)
         ) : (<div></div>) 
        }
        { (categ2Offers)? (
          (Object.keys(categ2Offers).length > 0)? (
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <ProductCarousel title={Config.homeSlider2Title} items={categ2Offers} />
            </div>
          </div>
          ) : (<div></div>)
         ) : (<div></div>)
        }
        { (categ3Offers)? (
          (Object.keys(categ3Offers).length > 0)? (
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <ProductCarousel
                title={Config.homeSlider3Title} items={categ3Offers} />
            </div>
          </div>
          ) : (<div></div>)
        ) : (<div></div>)
      }
      </div>
    </div>
  );
}
