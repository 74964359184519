import Checkbox from "@mui/material/Checkbox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import { isLoggedIn } from "../service/user-service";
import { Link } from "react-router-dom";
import Config from "../config.json";
import {
  stringToPrice,
  buildExpirationMessage,
} from "../service/formatting-service";

export default function ProductCard({
  id,
  name,
  price,
  regularPrice,
  date_on_sale_to,
  onHeartClick,
  wishlistId,
  featured_image,
  className,
  style,
}) {
  const [expirationMessage, expirationClassName] =
    buildExpirationMessage(date_on_sale_to);
  const productPageLink = "/pages/product/" + id;

  return (
    <span
      className={"col-6 col-md-4 theme-page-text mb-3 " + (className ?? "")}
      style={style}
    >
      <span className="row">
        <span className="col">
          {onHeartClick && (
            <Checkbox
              defaultChecked
              icon={<FavoriteBorder />}
              checkedIcon={<Favorite />}
              className="favorite-icon"
              onClick={(e) => onHeartClick(wishlistId, e.target.checked)}
              color="heartCheckbox"
            />
          )}
          <Link to={productPageLink}>
            <span className="row justify-content-center w-100 mx-0 pb-2">
              <img
                className= {"theme-page-text mw-100 mh-100" + (Config.verticalImages?? " wine-img-card")}
                src={featured_image}
                alt={name}
              />
            </span>
            <div className="font-16 theme-page-text">{name}</div>
            {(!Config.priceOnlyForLoggedIn || (Config.priceOnlyForLoggedIn && isLoggedIn())) &&  (
                  <span>
                    {
                      (price === "")? (
                        <span>
                          <strong className="theme-page-text">
                          {stringToPrice(regularPrice)}
                          </strong>
                        </span>
                      ) : (
                        <span>
                          <strong className="theme-page-text">
                            {stringToPrice(price)}
                          </strong>
                          {regularPrice && (
                            <del className="theme-page-text">
                              {stringToPrice(regularPrice)}
                            </del>
                           )}
                        </span>
                      )
                      
                    }
                  </span>
                )}
            {expirationMessage && (
              <div className={"font-12-14 " + expirationClassName}>
                {expirationMessage}
              </div>
            )}
          </Link>
        </span>
      </span>
    </span>
  );
}
