import SectionTitle from "../components/layout/section-title";
import { useHistory } from "react-router-dom";
import { path as landingPath } from "./landing-page";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { formatPageTitle } from "../service/formatting-service";

export const path = "/pages/order-aborted";
export default function OrderAbortedPage({ location, setCloseableOnMobile }) {
  const history = useHistory();

  useEffect(() => setCloseableOnMobile(false), [setCloseableOnMobile]);

  useEffect(() => {
    if (!location.state) {
      // Returns to the landing (e.g. a page refresh)
      history.replace(landingPath);
    }
  }, [history, location]);

  return (
    <>
      <Helmet>
        <title>{formatPageTitle("Ordine annullato")}</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="Ordine annullato" />
        <meta property="og:title" content={formatPageTitle("Ordine annullato")} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Ordine annullato" />
      </Helmet>
      <SectionTitle title="Ordine annullato" />
      <div className="row justify-content-center">
        <h2 className="col col-md-10 fw-600 font-18-24 theme-color-primary">
          Purtroppo c’è stato un problema con il tuo ordine
        </h2>
      </div>
      <div className="row justify-content-center">
        <p className="col col-md-10 fw-600 text-gray-2 font-16">
          Descrizione problema
        </p>
      </div>
      <div className="row justify-content-center mb-5">
        <p className="col col-md-10 fw-400 text-gray-2 font-16">
          {location?.state?.errorMessage}
        </p>
      </div>
    </>
  );
}
