import {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";
import SectionTitle from "../../components/layout/section-title";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { path as paymentThreePath } from "./payment3-page";
import { path as paymentOnePath } from "./payment1-page";
import { path as orderAbortedPath } from "../order-aborted-page";
import { path as cartPath } from "../cart-page";
import LoadingScreen from "../../components/layout/loading-screen";
import { InputAdornment } from "@mui/material";
import card from "../../assets/card.png";
import { getStripeIntentId } from "../../service/orders-service";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
//import { clearCart } from "../../service/cart-service";
import { formatPageTitle } from "../../service/formatting-service";
import { Helmet } from "react-helmet";
import { getCart } from "../../service/cart-service";
import PaymentBox from "../../components/payment-box";

export const path = "/payment/payment2";
export default function Payment2Page({ setBarPercentageLength, location }) {
  const [loading, setLoading] = useState(true);
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [cart, setCart] = useState({});

  useEffect(() => {
    let isSubscribed = true;
    if (!location.state) {
      // Returns to the first payment page if no state is passed (e.g. a page refresh)
      history.replace(paymentOnePath);
    } else {
      setBarPercentageLength(66);
      const { id, total } = location.state;
      getStripeIntentId(id, total).then(({ payment_intent_id }) => {
        if (isSubscribed) {
          setPaymentIntentId(payment_intent_id);
          setLoading(false);
        }
      });
    }
    Promise.all([
      getCart()
    ]).then(
      ([cartResult]) => {
        if (isSubscribed) {
          setCart(cartResult);
          if (!cartResult || cartResult.length < 1) {
            history.replace(cartPath);
          }
        }
      }
    );
    return () => (isSubscribed = false);
  }, [setBarPercentageLength, location.state, history]);

  if (loading) {
    return <LoadingScreen />;
  }

  const onPaymentFormSubmit = async (event) => {
    event.preventDefault();
    const cardHolderName = event.target[0].value;

    // Can't hide the form with loading since it would interfere with the iframe data retrieval from stripe.
    const cardNumberElement = elements.getElement("cardNumber");

    const paymentResult = await stripe.confirmCardPayment(paymentIntentId, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          name: cardHolderName,
        },
      },
    });

    if (paymentResult.error) {
      history.push({
        pathname: orderAbortedPath,
        state: { errorMessage: paymentResult.error.message },
      });
    } else {
      //clearCart();
      history.push(paymentThreePath);
    }
  };

  const StripeInput = ({ component: Component, inputRef, ...props }) => {
    const elementRef = useRef();
    useImperativeHandle(inputRef, () => ({
      focus: () => elementRef.current.focus,
    }));
    const options = { placeholder: "" };
    return (
      <Component
        onReady={(element) => (elementRef.current = element)}
        {...props}
        options={options}
      />
    );
  };

  const WrappedStripeInput = forwardRef((props, ref) => (
    <StripeInput inputRef={ref} {...props} />
  ));

  return (
    <>
      
    <div className="pb-md-3 pt-3 row flex-fill">
      <div className="col layout-max-width">
      <SectionTitle title="Pagamento e spedizione" subtitle="Verifica le eventuali spese di consegna e procedi al pagamento"/>      
        
        <div className="row justify-content-center">
        <div className="col-12 col-md-6">
            <form onSubmit={onPaymentFormSubmit} className="col layout-max-width">
            <Helmet>
              <title>{formatPageTitle("Pagamento e spedizione")}</title>
            </Helmet>
              <div className="row justify-content-center">
                <TextField
                  label="Titolare carta"
                  variant="standard"
                  className="col-md-10 col-10"
                  type="text"
                  required
                  color="textInput"
                />
              </div>
              <div className="row justify-content-center mt-3">
                <TextField
                  label="Numero carta"
                  variant="standard"
                  className="col-md-10 col-10"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  color="textInput"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={card} alt="card" />
                      </InputAdornment>
                    ),
                    inputComponent: WrappedStripeInput,
                    inputProps: {
                      component: CardNumberElement,
                    },
                  }}
                />
              </div>
              <div className="row justify-content-center mt-3">
                <TextField
                  label="MM/AA"
                  variant="standard"
                  className="col-md-5 pr-3 col-5"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  required
                  InputProps={{
                    inputComponent: WrappedStripeInput,
                    inputProps: {
                      component: CardExpiryElement,
                    },
                  }}
                  color="textInput"
                />
                <TextField
                  label="CVC"
                  variant="standard"
                  className="col-md-5 col-5"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: WrappedStripeInput,
                    inputProps: {
                      component: CardCvcElement,
                    },
                  }}
                  required
                  color="textInput"
                />
              </div>

              <div className="row justify-content-center my-5">
                <div className="col-10 col-md-10 p-0">
                  <Button
                    className="w-100"
                    type="submit"
                    variant="contained"
                    color="button"
                  >
                    Conferma
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 col-md-4 px-0">
            <PaymentBox
              cart={cart}
              title="Riepilogo Pagamento"
              className="p-3"
              false
              shipping={(cart?.shipping.packages.default? Object.keys(cart.shipping.packages.default.rates).length : 0)>0? cart?.totals.shipping_total : null}
            />
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
