import Config from "../config.json";


export async function getLastProducts() {
  const shop = Config.vendor ?? Config.activeTheme;
  return await fetch(`/wc/v3/products?shop=${shop}`);
}

export async function getExpiringProducts() {
  const shop = Config.vendor ?? Config.activeTheme;
  return await fetch(`/wc/v3/products?orderby=sale&order=asc&shop=${shop}`);
}

export async function getExclusiveProducts() {
  const shop = Config.vendor ?? Config.activeTheme;
  return await fetch(`/wc/v3/products?shop=${shop}`);
}

export async function getSingleProduct(id) {
  return await fetch(`/wc/v3/products/${id}`);
}

export async function getCorrelatedProducts(id) {
  return await fetch(`/apticashop/v1/related/${id}`);
}

export async function getSearchFilters() {
  return await fetch("/apticashop/v1/get_search_params");
}

export async function getProductsByKeyword(keyword) {
  const shop = Config.vendor ?? Config.activeTheme;
  return await fetch(`/wc/v3/products?shop=${shop}&search=${keyword}`);
}

export async function getProductsByCategory(category) {
  const shop = Config.vendor ?? Config.activeTheme;
  if(!category) return [];
  return await fetch(`/wc/v3/products?shop=${shop}&category_slug=${category}`);
}

export async function getCategory(category) {
  return await fetch(`/wc/v3/products/categories?slug=${category}`);
}

export async function searchProducts(
  category,
  keyword,
  sortDirection,
  attributes
) {
  const shop = Config.vendor ?? Config.activeTheme;
  let url = `/wc/v3/products?shop=${shop}`;
  if (category) {
     url += `&category_slug=${category}`;
   }
  if (keyword) {
    url += `&search=${keyword}`;
  }
  if (sortDirection) {
    url += `&orderby=price&order=${sortDirection}`;
  }
  if (attributes) {
    Object.entries(attributes).map(([attribute, values]) =>
      values.forEach(
        (value) => (url += `&attributes[${attribute}][]=${value.id}`)
      )
    );
  }
  return await fetch(url);
}
