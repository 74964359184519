import { useState, useEffect } from "react";
import SectionTitle from "../components/layout/section-title";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getUserProfile, updateUser } from "../service/user-service";
import LoadingScreen from "../components/layout/loading-screen";
import { formatPageTitle } from "../service/formatting-service";
import { Helmet } from "react-helmet";

export const path = "/pages/account";
export default function AccountPage({ setCloseableOnMobile }) {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [marketing, setMarketing] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (password && password !== confirmPassword) {
      setPasswordMismatchError(true);
      return;
    }

    setLoading(true);
    const response = await updateUser(
      email,
      password,
      name,
      surname,
      phone,
      newsletter,
      marketing
    );
    setLoading(false);
    setPasswordMismatchError(false);
    if (response.message) {
      setErrorMessage(response.message);
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => setCloseableOnMobile(false), [setCloseableOnMobile]);

  useEffect(() => {
    let isSubscribed = true;
    getUserProfile().then((response) => {
      if (isSubscribed) {
        setName(response.first_name);
        setSurname(response.last_name);
        setPhone(response.telephone);
        setEmail(response.email);
        setMarketing(response.marketing);
        setNewsletter(response.newsletter);
        setLoading(false);
      }
    });

    return () => (isSubscribed = false);
  }, []);

  if (loading) {
    return <LoadingScreen className="h-100" />;
  }

  return (
    <>
      <Helmet>
        <title>{formatPageTitle("Account")}</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="Account" />
        <meta property="og:title" content={formatPageTitle("Account")} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Account" />
      </Helmet>
      <SectionTitle
        title="Account"
        subtitle="Gestisci i dettagli del tuo account e i consensi"
      />

      <form onSubmit={onFormSubmit}>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Nome"
            variant="standard"
            className="col-10 col-md-6"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Cognome"
            variant="standard"
            className="col-10 col-md-6"
            type="text"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Telefono"
            variant="standard"
            className="col-10 col-md-6"
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Email"
            variant="standard"
            className="col-10 col-md-6"
            type="email"
            placeholder="mario.rossi@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Password attuale"
            variant="standard"
            type="password"
            className="col-10 col-md-6"
            value="placeholder"
            required
            disabled
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Nuova password"
            variant="standard"
            type="password"
            className="col-10 col-md-6"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={passwordMismatchError}
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Conferma nuova password"
            variant="standard"
            type="password"
            className="col-10 col-md-6"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={passwordMismatchError}
            helperText={
              passwordMismatchError ? "Le password inserite non coincidono" : ""
            }
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={newsletter}
                onChange={(e) => setNewsletter(e.target.checked)}
                color="checkbox"
              />
            }
            disableTypography={true}
            className="mb-0 text-gray-3 col-10 font-12 px-0 col-md-6"
            label="Voglio essere iscritto alla newsletter per rimanere sempre aggiornato"
          />
        </div>
        <div className="row justify-content-center">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={marketing}
                onChange={(e) => setMarketing(e.target.checked)}
                color="checkbox"
              />
            }
            disableTypography={true}
            className="mb-0 text-gray-3 font-12 col-10 px-0 col-md-6"
            label="Presto il consenso per il trattamento dei dati ai fini di marketing"
          />
        </div>

        {errorMessage && (
          <div className="row justify-content-center my-4">
            <div
              className="col-12 col-md-10 text-center text-danger"
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            ></div>
          </div>
        )}

        <div className="row my-4 justify-content-center">
          <Button
            className="col-10 col-md-6"
            type="submit"
            variant="contained"
            color="button"
          >
            Salva
          </Button>
        </div>
      </form>
    </>
  );
}
