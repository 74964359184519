import SectionTitle from "../components/layout/section-title";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPageContent } from "../service/page-service";
import { useState } from "react";
import LoadingScreen from "../components/layout/loading-screen";
import { useHistory } from "react-router-dom";
import { path as notFoundPath } from "../pages/notfound-page";
import { Helmet } from "react-helmet";
import { formatPageTitle } from "../service/formatting-service";
import Config from "../config.json";
import React from "react";

export const path = "/pages/content/:slug";
export const myRef = React.createRef();

export const UseExternalScript = (params) => {
  let url = Config.cookieScript;
  console.log("\n\n\nSLUG : ");
  console.log(params.slug);
  useEffect(() => {
    if (!url || params.slug!=='cookies') {
      return;
     }
    let cookieScript = document.querySelector('#CookieDeclaration');
    if (!cookieScript) {
      cookieScript = document.createElement('script');
      cookieScript.id="CookieDeclaration";
      cookieScript.src = url;
      cookieScript.async = true;
      console.log(myRef.current);
      myRef.current.appendChild(cookieScript);
    }
  }, [url,params.slug]);
  
  return (<div></div>);
};


export default function ContentPage({ setCloseableOnMobile }) {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState({});

  const { slug } = useParams();

  const history = useHistory();

  useEffect(() => {
    let isSubscribed = true;
    setLoading(true);
    getPageContent(slug).then((response) => {
      if (isSubscribed) {
        setLoading(false);
        if (response.length > 0) {
          setContent(response[0]);
        } else {
          history.replace(notFoundPath);
        }
      }
    });
    return () => (isSubscribed = false);
  }, [history, slug]);

  useEffect(() => setCloseableOnMobile(false), [setCloseableOnMobile]);

  if (loading) {
    return <LoadingScreen className="h-100" />;
  }

  

  return (
    <>
      <Helmet>
        <title>{formatPageTitle(content?.title?.rendered)}</title>
        <meta name="robots" content={(content?.yoast_head_json?.robots? content?.yoast_head_json?.robots.index+", "+content?.yoast_head_json?.robots.follow : "index, follow")} />
        <meta name="description" content={(content?.yoast_head_json?.description? content?.yoast_head_json?.description : (content?.description? content?.description : content?.title))} />
        <meta property="og:title" content={(content?.yoast_head_json?.og_title)? formatPageTitle(content?.yoast_head_json?.og_title) : formatPageTitle(content?.title?.rendered)} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={(content?.yoast_head_json?.og_description? content?.yoast_head_json?.og_description : (content?.description? content?.description : content?.title))} />
        <meta property="og:image" content={(content?.yoast_head_json?.og_image? content?.yoast_head_json?.og_image[0].url : (content?.images? content?.images.src : Config.projectLogo))} />
      </Helmet>
      <SectionTitle title={content?.title?.rendered} />
      <div className="row justify-content-center">
        <div 
          className="col col-md-10"
          dangerouslySetInnerHTML={{ __html: content?.content?.rendered }}
        ></div>
        <div 
          style={{marginTop: '20px'}}
          className="col col-md-10"
          id="pageContent"
          ref={myRef}>
        { <UseExternalScript slug={slug}/>}
        </div>
      </div>
    </>
  );
}
