import { useState, useEffect } from "react";
import Config from "../config.json";
import Footer from "../components/layout/footer";
import HeroFlashwine from "../components/landing/flashwine/hero-section";
import CtaFlashwine from "../components/landing/flashwine/cta-section";
import HighlightsFlashwine from "../components/landing/flashwine/highlights-section";
import { getFooterMenu, getCarouselMenu } from "../service/menu-service";
import { getUserProfile, isLoggedIn } from "../service/user-service";
import LoadingScreen from "../components/layout/loading-screen";
import HeaderFinesip from "../components/landing/finesip/header-finesip";
import HeaderFlashwine from "../components/landing/flashwine/header-flashwine";
import { getSlides } from "../service/carousel-service";
import { getNews } from "../service/news-service";
import {
  getExclusiveProducts,
  getLastProducts,
  getExpiringProducts,
  getProductsByCategory
} from "../service/product-service";
import { countCartItems } from "../service/cart-service";
import CarouselMenu from "../components/layout/carousel-menu";
import NewsSection from "../components/landing/finesip/news-section";
import ExpiringOffersSection from "../components/landing/finesip/expiring-offers-section";
import { formatPageTitle } from "../service/formatting-service";
import { Helmet } from "react-helmet";

export const path = Config.landingPath;
export default function LandingPage() {
  const [loading, setLoading] = useState(true);
  const [headerMenu, setHeaderMenu] = useState([]);
  const [footerMenu, setFooterMenu] = useState([]);
  const [headerSlides, setHeaderSlides] = useState([]);
  const [lastOffers, setLastOffers] = useState([]);
  const [lastNews, setLastnews] = useState([]);
  const [exclusiveOffers, setExclusiveOffers] = useState([]);
  const [expiringOffers, setExpiringOffers] = useState([]);

  const [categ1Offers, setCateg1Offers] = useState([]);
  const [categ2Offers, setCateg2Offers] = useState([]);
  const [categ3Offers, setCateg3Offers] = useState([]);

  const [cartItems, setCartItems] = useState(0);
  const [userProfile, setUserProfile] = useState({});

  const loggedIn = isLoggedIn();

  useEffect(() => {
    let isSubscribed = true;

    const slidesPromise = getSlides().then((slides) => {
      if (isSubscribed) {
        setHeaderSlides(slides);
      }
    });

    const promises = [
      getFooterMenu(),
      getCarouselMenu(),
      getExclusiveProducts(),
      getExpiringProducts(),
      getLastProducts(),
      getNews(),
      getProductsByCategory(Config.homeSlider1Categ),
      getProductsByCategory(Config.homeSlider2Categ),
      getProductsByCategory(Config.homeSlider3Categ),
      slidesPromise,
    ];

    if (loggedIn) {
      const countCartItemsPromise = countCartItems().then((cartItems) => {
        if (isSubscribed) {
          setCartItems(cartItems);
        }
      });

      const getUserProfilePromise = getUserProfile().then((profile) => {
        if (isSubscribed) {
          setUserProfile(profile);
        }
      });

      promises.push(countCartItemsPromise, getUserProfilePromise);
    }

    Promise.all(promises).then(
      ([
        footerMenuResult,
        headerMenuResult,
        exclusiveOffersResult,
        expiringProductsResult,
        lastProductsResult,
        newsResult,
        categ1OffersResult,
        categ2OffersResult,
        categ3OffersResult
      ]) => {
        if (isSubscribed) {
          setFooterMenu(footerMenuResult);
          setHeaderMenu(headerMenuResult);
          setExpiringOffers(expiringProductsResult);
          setExclusiveOffers(exclusiveOffersResult);
          setLastOffers(lastProductsResult);
          setLastnews(newsResult);
          setCateg1Offers(categ1OffersResult);
          setCateg2Offers(categ2OffersResult);
          setCateg3Offers(categ3OffersResult);
          setLoading(false);
        }
      }
    );

    return () => (isSubscribed = false);
  }, [loggedIn]);

  if (loading) {
    return (
      <div className="container-fluid min-vh-100">
        <LoadingScreen className="min-vh-100" />
      </div>
    );
  }

  const flashwineSlidesStyles = {
    subtextRow: "font-18-27 fw-300",
    titleRow: "font-26-45 fw-600",
  };

  const finesipSlidesStyles = {
    rectangleRow: "d-none",
    subtextRow: "text-center order-2 mb-4 font-17-23 fw-400",
    titleRow: "text-center order-1 font-32-50 fw-700",
    buttonRow: "text-center order-3",
    slideControllerRow: "order-4",
  };

  const header =
    Config.activeTheme === "additional" ? (
      <>
        <HeaderFinesip
          menu={headerMenu}
          cartItems={cartItems}
          styles={flashwineSlidesStyles}
          isVendor={userProfile.is_vendor}
        />
        <CarouselMenu slides={headerSlides} styles={finesipSlidesStyles} />
      </>
    ) : (
      <HeaderFlashwine
        slides={headerSlides}
        menu={headerMenu}
        cartItems={cartItems}
        isVendor={userProfile.is_vendor}
      />
    );

  const showLanding = Config.activeTheme === "main" && Config.showRegisterLogin && !loggedIn;

  let themeMap = {
    hero: showLanding ? <HeroFlashwine /> : header,
    cta: showLanding ? <CtaFlashwine /> : <></>,
    highlights: (
      <HighlightsFlashwine
        lastOffers={lastOffers}
        exclusiveOffers={exclusiveOffers}
        expiringOffers={expiringOffers}
        categ1Offers={categ1Offers}
        categ2Offers={categ2Offers}
        categ3Offers={categ3Offers}
      />
    ),
    expiring: <ExpiringOffersSection expiringOffers={expiringOffers} />,
    news: <NewsSection posts={lastNews} />,
    footer: <Footer menu={footerMenu} />,
  };

  const landingContent = Config.landingPage.map((c) => {
    return { ...themeMap[c], key: c };
  });

  return (
    <div className="container-fluid">
      <Helmet>
        <title>{formatPageTitle()}</title>
      </Helmet>
      {landingContent}
    </div>
  );
}
