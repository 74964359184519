import { useState, useEffect } from "react";
import SectionTitle from "../components/layout/section-title";
import { useHistory } from "react-router-dom";
import { path as cartPath } from "./cart-page";
import { getCart } from "../service/cart-service";
import { clearCart } from "../service/cart-service";
import { Helmet } from "react-helmet";
import Config from "../config.json";
import LoadingScreen from "../components/layout/loading-screen";
import { formatPageTitle,printEmailTo } from "../service/formatting-service";
import { createOrder } from "../service/orders-service";
import {
  getCustomerInfo,
  saveCustomerInfo,
  getUserId,
  getUserProfile,
} from "../service/user-service";
export const path = "/pages/order-confirmed";
export default function OrderConfirmedPage({ location, setCloseableOnMobile }) {
  console.log("\n\nORDER CONFIRMED\n\n");
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [billingAddress, setBillingAddress] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const [shippingLines, setShippingLines] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  
  useEffect(() => {
    let isSubscribed = true;
    Promise.all([
      getCustomerInfo(getUserId()),
      getUserProfile(),
      getCart(),
    ]).then(
      ([{ billing, shipping }, { first_name, last_name }, cartResult]) => {
        console.log("\n\nESEGUO\n\n");
        if (isSubscribed) {
          if (!cartResult || cartResult.length < 1) {
            history.replace(cartPath);
          } else {
            console.log("--------CART--------");
            console.log(cartResult);
            console.log("--------CART--------");
            const orderItems = cartResult.items.map(({ id, quantity }) => {
              return { product_id: id, quantity: quantity.value };
            });
            shippingAddress.first_name = first_name;
            shippingAddress.last_name = last_name;
            billingAddress.first_name = first_name;
            billingAddress.last_name = last_name;
            billingAddress.address_1 = "";
            billingAddress.address_2 = "";
            billingAddress.city = "";
            billingAddress.country = "";
            billingAddress.postcode = "";
            billingAddress.state = "";
            billingAddress.company = "";
            //Aggiorno Default Utente
            const response = saveCustomerInfo(getUserId(), {
              billing: billingAddress,
              shipping: shippingAddress,
            });
            shippingLines.method_id = null;
            shippingLines.method_title =  '';
            shippingLines.total = "0";
            const { id, total, message } = createOrder(
              billingAddress,
              shippingAddress,
              orderItems,
              [shippingLines]
            );
            if (message) {
              setErrorMessage(message);
            }
            clearCart();
            setLoading(false);
          }
          return () => (isSubscribed = false);
        }
        setLoading(false);
      }
    );
  }, [billingAddress, history, shippingAddress, shippingLines]);


  if (loading) {
    return <LoadingScreen />;
  }


  return (
    <>
      <Helmet>
        <title>{formatPageTitle(Config.translations[Config.language]['ordine_confermato'])}</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="{Config.translations[Config.language]['ordine_confermato']}" />
        <meta property="og:title" content={formatPageTitle(Config.translations[Config.language]['ordine_confermato'])} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="{Config.translations[Config.language]['ordine_confermato']}" />
      </Helmet>
      <SectionTitle title={Config.translations[Config.language]['ordine_confermato']} />
      <div className="row justify-content-center">
        <h2 className="col col-md-10 fw-600 font-18-24 theme-color-primary">
        {Config.translations[Config.language]['ordine_confermato']}
        </h2>
      </div>
      <div className="row justify-content-center">
        <p className="col col-md-10 fw-600 text-gray-2 font-16 justify-content-center" style={{textAlign: 'center'}} w>
        {Config.translations[Config.language]['ordine_confermato_msg_1']} <br/>{Config.translations[Config.language]['scrivi']} <a href={printEmailTo()}>{Config.translations[Config.language]['qui']}</a> {Config.translations[Config.language]['ordine_confermato_msg_2']}
        </p>
      </div>
    </>
  );
  
}