import { useState, useEffect } from "react";
import ProductCard from "../components/product-card";
import SectionTitle from "../components/layout/section-title";
import { getWishlist, removeFromWishlist } from "../service/wishlist-service";
import LoadingScreen from "../components/layout/loading-screen";
import { getUserWishlistId } from "../service/user-service";
import { Helmet } from "react-helmet";
import { formatPageTitle } from "../service/formatting-service";

export const path = "/pages/wishlist";
export default function WishlistPage({ setCloseableOnMobile }) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const wishlistId = getUserWishlistId();

  useEffect(() => setCloseableOnMobile(false), [setCloseableOnMobile]);

  useEffect(() => {
    let isSubscribed = true;
    getWishlist(wishlistId).then((products) => {
      if (isSubscribed) {
        setProducts(products);
        setLoading(false);
      }
    });

    return () => (isSubscribed = false);
  }, [wishlistId]);

  if (loading) {
    return <LoadingScreen className="h-100" />;
  }

  const onHeartClick = async (id) => {
    setLoading(true);
    await removeFromWishlist(wishlistId, id);
    const wishlist = await getWishlist(wishlistId);
    setProducts(wishlist);
    setLoading(false);
  };

  const wineCards = products.map((w, i) => (
    <ProductCard
      {...w.product}
      key={i}
      onHeartClick={onHeartClick}
      wishlistId={w.item_id}
    />
  ));

  return (
    <>
      <Helmet>
        <title>{formatPageTitle("Wishlist")}</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="Wishlist" />
        <meta property="og:title" content={formatPageTitle("Wishlist")} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Wishlist" />
      </Helmet>
      <SectionTitle title="Wishlist" subtitle="Le tue offerte preferite" />
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="row">{wineCards}</div>
        </div>
      </div>
    </>
  );
}
