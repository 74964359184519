import TextField from "@mui/material/TextField";

export default function AddressForm({ address, setAddress }) {
  const onAddressChange = (e) => {
    const newAddress = { ...address };
    newAddress[e.target.name] = e.target.value;
    setAddress(newAddress);
  };

  return (
    <>
      <div className="row mt-3 justify-content-center">
        <TextField
          name="address_1"
          label="Indirizzo"
          variant="standard"
          className="col-10 col-md-10"
          type="text"
          value={address.address_1}
          onChange={onAddressChange}
          /*required*/
          color="textInput"
        />
      </div>
      <div className="row mt-3 justify-content-center">
        <TextField
          name="address_2"
          label="Indirizzo"
          variant="standard"
          className="col-10 col-md-10"
          value={address.address_2}
          onChange={onAddressChange}
          type="text"
          color="textInput"
        />
      </div>
      <div className="row mt-3 justify-content-center">
        <TextField
          name="postcode"
          label="Cap"
          variant="standard"
          className="col-10 col-md-10"
          value={address.postcode}
          onChange={onAddressChange}
          type="number"
          required
          color="textInput"
        />
      </div>
      <div className="row mt-3 justify-content-center">
        <TextField
          name="city"
          label="Città"
          variant="standard"
          className="col-10 col-md-10"
          value={address.city}
          onChange={onAddressChange}
          type="text"
          color="textInput"
        />
      </div>
      {/*}
      <div className="row mt-3 justify-content-center">
        <TextField
          name="country"
          label="Provincia"
          variant="standard"
          type="text"
          className="col-10 col-md-10"
          value={address.country}
          onChange={onAddressChange}
          color="textInput"
        />
      </div>
      */}
      <div className="row my-3 justify-content-center">
        <TextField
          name="state"
          label="Paese"
          variant="standard"
          type="text"
          className="col-10 col-md-10"
          value={address.state}
          onChange={onAddressChange}
          color="textInput"
        />
      </div>
    </>
  );
}
