import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import ProductCard from "../components/product-card";
import SectionTitle from "../components/layout/section-title";
import {
  getSearchFilters,
  searchProducts,
  getCategory,
} from "../service/product-service";
import LoadingScreen from "../components/layout/loading-screen";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import { Button, FormControlLabel, Checkbox, Chip } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { formatPageTitle } from "../service/formatting-service";
import { path as notFoundPath } from "../pages/notfound-page";
import { Helmet } from "react-helmet";
import Config from "../config.json";
//import { ContrastOutlined } from "@mui/icons-material";

export const path = ["/pages/listing/:id", "/pages/listing"];
export default function ListingPage({ setCloseableOnMobile, location }) {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [searchFilters, setSearchFilters] = useState();
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState();
  const [category, setCategory] = useState();
  const [activeFilters, setActiveFilters] = useState({});
  const [tmpActiveFilters, setTmpActiveFilters] = useState({});
  const [mouseOverSortButton, setMouseOverSortButton] = useState(false);
  const [mouseOverSortMenu, setMouseOverSortMenu] = useState(false);
  const [sortMenuOpen, setSortMenuOpen] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  // Resets the sort menu
  if (!isMobile && sortMenuOpen) {
    setSortMenuOpen(false);
  }

  const query = new URLSearchParams(location.search);
  const keyword = query.get("search");

  useEffect(() => setCloseableOnMobile(false), [setCloseableOnMobile]);
  
  useEffect(() => {

    const removeNoScroll = () => {
      document.body.classList.remove("no-scroll");
      document.documentElement.classList.remove("no-scroll");
    };
    if (filterMenuOpen) {
      document.body.classList.add("no-scroll");
      document.documentElement.classList.add("no-scroll");
    } else {
      removeNoScroll();
    }
    return removeNoScroll;
  }, [filterMenuOpen]);

  useEffect(() => {
    
    if(!id && !keyword){
      history.replace(notFoundPath);
      return;
    }
    

    let isSubscribed = true;
    setLoading(true);
    const promises = [
      searchProducts(id, keyword, sortDirection, activeFilters),
    ];



    if (!searchFilters) {
      const getSearchFiltersPromise = getSearchFilters().then(
        (searchFilters) => {
          if (isSubscribed) {
            setSearchFilters(searchFilters);
          }
        }
      );
      promises.push(getSearchFiltersPromise);
    }

    if (id) {
      const getCategoryPromise = getCategory(id).then((categories) => {
        if (isSubscribed) {
          if(categories.length>0){
            setCategory(categories[0]);
          }else{
            console.log("\n1\n");
            history.replace(notFoundPath);
          }
        }
      });
      promises.push(getCategoryPromise);
    } else {
      if (isSubscribed) {
        setCategory();
      }
    }

    Promise.all(promises).then(([products, ...rest]) => {
      if (isSubscribed) {
        setProducts(products);
        setLoading(false);
      }
    });

    return () => (isSubscribed = false);
  }, [id, searchFilters, keyword, sortDirection, location, activeFilters, history]);

  if (loading) {
    return <LoadingScreen className="h-100" />;
  }

  const wineCards = products.map((w, i) => (
    <ProductCard {...w} key={i} className="mt-4" />
  ));

  const openFilterMenu = () => {
    setTmpActiveFilters({ ...activeFilters });
    setFilterMenuOpen(true);
  };

  const closeFilterMenu = () => {
    setFilterMenuOpen(false);
  };

  const onResetFiltersClick = () => {
    setActiveFilters({});
    setTmpActiveFilters({});
    setFilterMenuOpen(false);
  };

  const onApplyFiltersClick = () => {
    setActiveFilters({ ...tmpActiveFilters });
    setTmpActiveFilters({});
    setFilterMenuOpen(false);
  };

  // Handles the menu opening and closing
  let sortMenuClass = "shadow bg-white position-absolute z-1 right-0";
  if (
    (isMobile && sortMenuOpen) ||
    (!isMobile && (mouseOverSortButton || mouseOverSortMenu))
  ) {
    sortMenuClass += " d-block";
  } else {
    sortMenuClass += " d-none";
  }

  const toggleFilter = (event, attribute, value) => {
    let newFilters = { ...tmpActiveFilters };
    if (!newFilters[attribute]) {
      newFilters[attribute] = [];
    }
    if (event.target.checked) {
      newFilters[attribute].push(value);
    } else {
      newFilters[attribute] = newFilters[attribute].filter(
        (i) => i.id !== value.id
      );
    }
    setTmpActiveFilters(newFilters);
  };

  const isFilterChecked = (attribute, value) => {
    return (
      tmpActiveFilters[attribute]?.find((i) => i.id === value.id) !== undefined
    );
  };

  const searchFiltersHtml = searchFilters.attributes.map((a, i) => (
    <div className="row mb-4" key={i}>
      <div className="col ml-2">
        <div className="row">
          <p className="col fw-600 font-16 m-0">{a.name}</p>
        </div>
        {a.values.map((s, i) => (
          <div className="row" key={i}>
            <div className="col">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isFilterChecked(a.slug, s)}
                    onChange={(e) => toggleFilter(e, a.slug, s)}
                    className="pl-0"
                    color="checkboxSinglePage"
                    sx={{ color: "checkboxSinglePage.contrastText" }}
                    required
                  />
                }
                disableTypography={true}
                label={s.name}
                className="font-16 fw-300 m-0"
              ></FormControlLabel>
            </div>
          </div>
        ))}
      </div>
    </div>
  ));

  const deleteAttribute = (attribute, value) => {
    let newFilters = { ...activeFilters };
    newFilters[attribute] = newFilters[attribute].filter(
      (i) => i.id !== value.id
    );
    setActiveFilters(newFilters);
  };

  const activeFiltersHtml = Object.entries(activeFilters).map(
    ([attribute, values]) =>
      values.map((value) => (
        <Chip
          key={value.id}
          onDelete={() => deleteAttribute(attribute, value)}
          label={value.name}
          className="mb-1 mr-1"
          color="listingPageChip"
        />
      ))
  );

  const changeSortDirection = (sortDirection) => {
    setSortDirection(sortDirection);
    setSortMenuOpen(false);
  };

  // Handles menu overlay
  const menuClosingDelay = 300;
  const onMouseOverSortMenu = () => setMouseOverSortMenu(true);
  const onMouseLeaveSortMenu = () =>
    setTimeout(() => setMouseOverSortMenu(false), menuClosingDelay);
  const onMouseOverSortButton = () => setMouseOverSortButton(true);
  const onMouseLeaveSortButton = () =>
    setTimeout(() => setMouseOverSortButton(false), menuClosingDelay);
  const toggleSortMenuOpen = () => setSortMenuOpen(!sortMenuOpen);

  const sectionTitle = id ?  id : "Risultati ricerca";
  return (
    <>
      <Helmet>
        <title>{formatPageTitle((category)? category.name : Config.translations[Config.language]['ricerca'])}</title>
        <meta name="robots" content={(category)? (category.yoast_head_json.robots? category.yoast_head_json.robots.index+", "+category.yoast_head_json.robots.follow : "index, follow") : "index, follow"} />
        <meta name="description" content={(category)? (category.yoast_head_json.description? category.yoast_head_json.description : (category.description? category.description : category.title)) : "Ricerca di "+keyword} />
        <meta property="og:title" content={formatPageTitle((category)? category.yoast_head_json.og_title : Config.translations[Config.language]['ricercadi']+keyword)} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={(category)? (category.yoast_head_json.description? category.yoast_head_json.description : (category.description? category.description : category.title)) : "Ricerca di "+keyword} />
        <meta property="og:image" content={(category)? (category.yoast_head_json.og_image? category.yoast_head_json.og_image[0].url : (category.images? category.images.src : Config.projectLogo)) : Config.projectLogo} />
      </Helmet>
      <SectionTitle
        title={category?.name ?? ""}
        subtitle={category?.description ?? ""}
      />
      <div className="row justify-content-center">
        <div className="col-md-5 col-4 text-left theme-page-text">
          <span onClick={openFilterMenu} className="cursor-pointer">
            <SettingsIcon color="listingPageIcon" />
            <p className="mb-0 d-inline">{Config.translations[Config.language]['filtra']}</p>
          </span>
        </div>
        <div className="col-md-5 col-8 text-right theme-page-text">
          <span
            className="cursor-pointer"
            onClick={toggleSortMenuOpen}
            onMouseOver={onMouseOverSortButton}
            onMouseLeave={onMouseLeaveSortButton}
          >
            <SortOutlinedIcon color="listingPageIcon" />
            <p className="mb-0 d-inline">{Config.translations[Config.language]['ordina']}</p>
          </span>
          <div
            className={sortMenuClass}
            onMouseOver={onMouseOverSortMenu}
            onMouseLeave={onMouseLeaveSortMenu}
          >
            <p
              className="m-0 py-2 px-3 text-left hover-gray-light theme-page-text text-uppercase cursor-pointer"
              onClick={() => changeSortDirection("desc")}
            >
              Prezzo più alto
            </p>
            <p
              className="m-0 py-2 px-3 text-left hover-gray-light theme-page-text text-uppercase cursor-pointer"
              onClick={() => changeSortDirection("asc")}
            >
              Prezzo più basso
            </p>
          </div>
        </div>
      </div>
      <div className="row mt-3 justify-content-center">
        <div className="col-12 col-md-10">{activeFiltersHtml}</div>
      </div>

      <div
        className={"black-overlay " + (filterMenuOpen ? "d-block" : "d-none")}
      ></div>
      <div
        className={
          "container-fluid filter-menu theme-single-page " +
          (filterMenuOpen ? "filter-menu-open" : "filter-menu-closed")
        }
      >
        <div className="row">
          <div className="col">
            <div className="row justify-content-end mt-2">
              <div className="col text-right">
                <CloseIcon
                  fontSize="large"
                  onClick={closeFilterMenu}
                  className="cursor-pointer theme-single-page-text"
                />
              </div>
            </div>
            <div className="row">
              <p className="col text-uppercase fw-600 font-18">{Config.translations[Config.language]['filtra_per']}</p>
            </div>
            {searchFiltersHtml}
            <div className="row shadow py-4 justify-content-around position-sticky bottom-0 theme-single-page">
              <div className="col-6">
                <Button
                  variant="outlined"
                  onClick={onResetFiltersClick}
                  className="w-100"
                  color="button"
                >
                  {Config.translations[Config.language]['elimina']}
                </Button>
              </div>
              <div className="col-6">
                <Button
                  variant="contained"
                  onClick={onApplyFiltersClick}
                  className="w-100"
                  color="button"
                >
                  {Config.translations[Config.language]['applica']}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mb-4">
        <div className="col-12 col-md-10">
          <div className="row">{wineCards}</div>
        </div>
      </div>
    </>
  );
}
