import { useState, useEffect } from "react";
import SectionTitle from "../components/layout/section-title";
import Button from "@mui/material/Button";
import LoadingScreen from "../components/layout/loading-screen";
import { TextField, FormControlLabel, Checkbox } from "@mui/material";
import { Link } from "react-router-dom";
import { upgradeToVendor } from "../service/vendor-service";
import { useHistory } from "react-router-dom";
import { path as landingPath } from "../pages/landing-page";
import { path as loginPath } from "../pages/login-page";
import { formatPageTitle } from "../service/formatting-service";
import { Helmet } from "react-helmet";
import { getUserProfile } from "../service/user-service";
import { isLoggedIn } from "../service/user-service";


export const path = "/pages/vendor";
export default function VendorPage({ setCloseableOnMobile }) {
  const [loading, setLoading] = useState(true);
  const [businessName, setBusinessName] = useState("");
  const [vat, setVat] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [rea, setRea] = useState("");
  const [invoiceCode, setInvoiceCode] = useState("");
  const [pec, setPec] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  useEffect(() => {
    let isSubscribed = true;
    if (!isLoggedIn()) {
      history.push(loginPath);
      return;
    }
    setCloseableOnMobile(false);
    getUserProfile().then((response) => {
      if (isSubscribed) {
        if (response.is_vendor?.toUpperCase() !== "FALSE") {
          history.replace(landingPath);
        }
        setLoading(false);
      }
    });

    return () => (isSubscribed = false);
  }, [history, setCloseableOnMobile]);

  const onVendorUpgradeFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await upgradeToVendor(
      name,
      email,
      businessName,
      vat,
      zip,
      address,
      city,
      province,
      rea,
      invoiceCode,
      pec
    );
    if (response.message) {
      setErrorMessage(response.message);
    } else {
      setErrorMessage("");
      history.push(landingPath);
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingScreen className="h-100" />;
  }

  return (
    <>
      <Helmet>
        <title>{formatPageTitle("Apri il tuo shop")}</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="Apri il tuo shop" />
        <meta property="og:title" content={formatPageTitle("Apri il tuo shop")} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Apri il tuo shop" />
      </Helmet>
      <SectionTitle
        title="Apri il tuo shop"
        subtitle="Completa tutti i campi e diventa venditore"
      />
      <form onSubmit={onVendorUpgradeFormSubmit}>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Ragione sociale"
            variant="standard"
            className="col-10 col-md-6"
            type="text"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Partita IVA"
            variant="standard"
            className="col-10 col-md-6"
            type="text"
            value={vat}
            onChange={(e) => setVat(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Indirizzo"
            variant="standard"
            className="col-10 col-md-6"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Codice postale"
            variant="standard"
            className="col-10 col-md-6"
            type="text"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Città"
            variant="standard"
            className="col-10 col-md-6"
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Provincia"
            variant="standard"
            className="col-10 col-md-6"
            type="text"
            value={province}
            onChange={(e) => setProvince(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Numero RI/REA"
            variant="standard"
            className="col-10 col-md-6"
            type="text"
            value={rea}
            onChange={(e) => setRea(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Codice di fatturazione"
            variant="standard"
            className="col-10 col-md-6"
            type="text"
            value={invoiceCode}
            onChange={(e) => setInvoiceCode(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="PEC"
            variant="standard"
            className="col-10 col-md-6"
            type="email"
            value={pec}
            onChange={(e) => setPec(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Email"
            variant="standard"
            className="col-10 col-md-6"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row mt-3 justify-content-center">
          <TextField
            label="Nome"
            variant="standard"
            className="col-10 col-md-6"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row justify-content-center mt-3">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={privacy}
                onChange={(e) => setPrivacy(e.target.checked)}
                color="checkbox"
                required
              />
            }
            disableTypography={true}
            className="mb-0 col-md-6 text-left px-0 col-10 font-12"
            label={
              <span>
                Ho letto e accetto{" "}
                <u>
                  <Link
                    className="colored-link text-gray-3 "
                    to="/pages/content/privacy"
                  >
                    privacy
                  </Link>{" "}
                </u>
                e{" "}
                <u>
                  <Link
                    className="colored-link text-gray-3 "
                    to="/pages/content/termini-e-condizioni"
                  >
                    termini di utilizzo
                  </Link>
                </u>
                *
              </span>
            }
          />
        </div>
        {errorMessage && (
          <div className="row justify-content-center my-4">
            <div
              className="col-12 col-md-10 text-center text-danger"
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            ></div>
          </div>
        )}
        <div className="row justify-content-center my-4">
          {!errorMessage && (
          <Button
            type="submit"
            className="col-10 col-md-6"
            variant="contained"
            color="button"
          >
            Registra azienda
          </Button>
          )}
        </div>
      </form>
    </>
  );
}
