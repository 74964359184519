import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useHistory } from "react-router-dom";
import { path as loginPath } from "../../../pages/login-page";
import { path as signUpPath } from "../../../pages/signup-page";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ThemeProvider } from "@mui/material/styles";
import flashwineNegative from "../../../assets/flashwine-negative.png";
import banner from "../../../assets/banner.png";
import { useState, useRef } from "react";
import { registerUser } from "../../../service/user-service";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MediaQuery, { useMediaQuery } from "react-responsive";
import LoadingScreen from "../../layout/loading-screen";
import Config from "../../../config.json";
import { createTheme } from "@mui/material";

export default function HeroSection() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [marketing, setMarketing] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);

  const scrollRef = useRef();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const history = useHistory();

  if (loading) {
    return <LoadingScreen />;
  }

  const heroMuiTheme = createTheme({
    palette: {
      primary: {
        main: Config.themes[Config.activeTheme].colorPrimary,
      },
      text: {
        primary: "#ffffff",
        secondary: "#ffffff",
      },
    },
    shape: {
      borderRadius: 0,
    },
  });

  const onRegisterFormSubmit = async (event) => {
    if (isMobile) {
      history.push(signUpPath);
    } else {
      event.preventDefault();

      if (password && password !== confirmPassword) {
        setPasswordMismatchError(true);
        return;
      }
      setPasswordMismatchError(false);

      setLoading(true);
      const response = await registerUser(
        email,
        password,
        name,
        surname,
        phone,
        privacy,
        marketing
      );
      setLoading(false);
      if (response.message) {
        setErrorMessage(response.message);
      } else {
        setErrorMessage("");
        history.push(loginPath);
      }
    }
  };

  const onScrollClick = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      id="hero"
      className="row"
      style={{ backgroundImage: `url(${banner})` }}
    >
      <div className="col layout-max-width">
        <div className="row justify-content-center m-5">
          <img src={flashwineNegative} alt="Flashwine" width="317" />
        </div>
        <div className="row mx-4">
          <p className="text-white ln-30 font-20 fw-300 text-center col-12 mb-0">
            IL PRIMO <strong className="fw-600">PRIVATE FLASH SHOP</strong> DEL
            VINO
          </p>
        </div>
        <div className="row mx-4">
          <p className="text-white fw-600 text-center col-12 font-30-35">
            ISCRIVITI ORA GRATUITAMENTE
          </p>
        </div>
        <div className="d-flex justify-content-center mb-4 pb-5">
          <div className="d-flex flex-column transparent-overlay">
            <form className="mx-5 px-5 py-5" onSubmit={onRegisterFormSubmit}>
              <ThemeProvider theme={heroMuiTheme}>
                <MediaQuery minWidth={768}>
                  <div className="row mb-4 justify-content-around">
                    <TextField
                      label="Nome"
                      variant="standard"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="overlay-input mx-3"
                    />
                    <TextField
                      label="Cognome"
                      variant="standard"
                      type="text"
                      value={surname}
                      onChange={(e) => setSurname(e.target.value)}
                      className="overlay-input mx-3"
                    />
                  </div>
                  <div className="row mb-4 justify-content-around">
                    <TextField
                      label="Telefono"
                      variant="standard"
                      type="tel"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="overlay-input mx-3"
                    />
                    <TextField
                      label="Email"
                      variant="standard"
                      type="email"
                      placeholder="mario.rossi@gmail.com"
                      className="overlay-input mx-3"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="row mb-4 justify-content-around">
                    <TextField
                      label="Password"
                      variant="standard"
                      type="password"
                      className="overlay-input mx-3"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={passwordMismatchError}
                      required
                    />
                    <TextField
                      label="Ripeti password"
                      variant="standard"
                      type="password"
                      className="overlay-input mx-3"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      error={passwordMismatchError}
                      helperText={
                        passwordMismatchError
                          ? "Le password inserite non coincidono"
                          : ""
                      }
                      required
                    />
                  </div>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={privacy}
                          onChange={(e) => setPrivacy(e.target.checked)}
                          required
                        />
                      }
                      disableTypography={true}
                      className="mb-0"
                      label={
                        <span className="text-white">
                          Ho letto e accetto{" "}
                          <u>
                            <Link
                              className="colored-link text-white"
                              to="/pages/content/privacy"
                            >
                              privacy
                            </Link>
                          </u>{" "}
                          e{" "}
                          <u>
                            <Link
                              className="colored-link text-white"
                              to="/pages/content/termini-e-condizioni"
                            >
                              termini di utilizzo
                            </Link>
                          </u>
                          *
                        </span>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={marketing}
                          onChange={(e) => setMarketing(e.target.checked)}
                        />
                      }
                      disableTypography={true}
                      className="mb-0 text-white"
                      label="Presto il consenso per il trattamento dei dati ai fini di marketing"
                    />
                  </FormGroup>
                </MediaQuery>

                {errorMessage && (
                  <div className="row justify-content-center my-4">
                    <div
                      className="col-12 col-md-10 text-center text-danger"
                      dangerouslySetInnerHTML={{ __html: errorMessage }}
                    ></div>
                  </div>
                )}

                <div className="row justify-content-center">
                  <Button
                    variant="contained"
                    id="home-cta-button"
                    type="submit"
                  >
                    Registrati
                  </Button>
                </div>
                <div className="row justify-content-center mt-3 text-center text-white">
                  <sub>
                    Hai già un account?
                    <u>
                      <Link
                        className="colored-link theme-color-primary"
                        to={loginPath}
                      >
                        {Config.translations[Config.language]['accedi']}
                      </Link>
                    </u>
                  </sub>
                </div>
              </ThemeProvider>
            </form>
          </div>
        </div>
        <MediaQuery maxWidth={768}>
          <span onClick={onScrollClick} className="cursor-pointer">
            <div className="text-center">
              <p className="text-white mb-0">Scorri</p>
            </div>
            <div className="d-flex justify-content-center">
              <KeyboardArrowDownIcon className="text-white" size="large" />
            </div>
          </span>
        </MediaQuery>
        <div ref={scrollRef}></div>
      </div>
    </div>
  );
}
